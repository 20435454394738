<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title> Recuperar Contraseña </v-card-title>

        <v-card-text class="mt-8">
          <v-form @submit.prevent="save" ref="formNewUser" autocomplete="off">
            <v-row>
              <!-- <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="name"
                  outlined
                  label="Nombre"
                ></v-text-field> -->
              <!-- :error-messages="errores.name" -->
              <!-- </v-col> -->
              <v-col lg="12" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="email"
                  outlined
                  label="Email"
                  :error-messages="errores.email || errores.name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn @click="close()" color="secondary" dark> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isLoading"
            @click="save()"
            type="submit"
            color="primary"
            dark
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BackendApi from "@/services/backend.service";

export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      email: null,
      errores: {
        email: null,
        name: null,
      },
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async save() {
      this.isLoading = true;
      const payload = {
        email: this.email,
      };
      BackendApi.post("/auth/recoverPassword", payload)
        .then((response) => {
          if (response.data.success) {
            // this.$emit("newUser");
            this.close();
          }
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response.data.errors) {
            this.errores[error.response.data.errors[0]?.param] =
              error.response.data.errors[0]?.msg;
          } else {
            this.errores.name = error.response.data?.msg;
          }
        });
    },
  },
};
</script>

<style></style>
