<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <picture class="content-img pt-8 pb-4">
            <img
              class="img-logo"
              src="@/assets/img/auth/pulse_logo.png"
              alt="Logo teca"
            />
          </picture>
          <div class="text-center">
            <h5 class="text-h5 text-typo font-weight-bold">INICIO SESIÓN</h5>
          </div>
          <div class="card-padding">
            <v-form @submit.prevent="login">
              <v-text-field
                v-model="email"
                outlined
                placeholder="Email"
                :error-messages="errorEmail || errorMessage"
              />

              <v-text-field
                v-model="password"
                placeholder="Password"
                :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                outlined
                :error-messages="errorPassword || errorMessage"
                @click:append="showPassword = !showPassword"
                @keyup.enter="login"
              ></v-text-field>
              <!-- @change="resetErrors" -->
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="w-100"
                color="#00BFEF"
                small
                type="submit"
                dark
                :loading="btnLoading"
              >
                Ingresar
              </v-btn>
              <div class="text-center">
                <small @click="openRecoverPassword">
                  <a href="#">Recuperar contraseña</a>
                </small>
              </div>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <RecoverPassword ref="recoverPassword" />
  </v-container>
</template>

<script>
// import BackendApi from "@/services/backend.service";
import AuthService from "@/services/auth.service";
import RecoverPassword from "./components/RecoverPassword.vue";
export default {
  name: "LoginView",
  components: {
    RecoverPassword,
  },
  data() {
    return {
      email: null,
      password: null,
      error: false,
      showPassword: false,
      errorMessage: null,
      errorEmail: null,
      errorPassword: null,
      btnLoading: false,
    };
  },
  computed: {
    showErrorEmail() {
      return this.errorEmail || this.errorMessage;
    },
    showErrorPassword() {
      return this.errorEmail || this.errorMessage;
    },
  },
  methods: {
    async login() {
      this.btnLoading = true;
      this.errorMessage = null;
      this.errorEmail = null;
      this.errorPassword = null;

      const payload = {
        email: this.email,
        password: this.password,
      };
      const data = await AuthService.login(payload);
      if (data.success) {
        console.log(data);
        this.$cookies.set("token", data.token);
        this.$cookies.set("user", data.user);
        this.$cookies.set("isAdmin", data.isAdmin);
        this.$cookies.set("isSuperAdmin", data.isSuperAdmin);
        this.$cookies.set("color", data.user.brand_blank.color);
        this.$cookies.set("footer", data.user.brand_blank.footer);
        this.$cookies.set("logo", data.user.brand_blank.logo);
        if (!data.isSuperAdmin) {
          this.$cookies.set(
            "agua",
            data.devices.some((device) => device.name === "agua")
          );
          this.$cookies.set(
            "energia",
            data.devices.some((device) => device.name === "energía")
          );
          this.$cookies.set(
            "gas",
            data.devices.some((device) => device.name === "gas")
          );
        }

        this.$router.push({ name: "Gestión de usuarios" });
        this.btnLoading = false;
      } else {
        if (data.errors) {
          this.errorEmail = data.errors[0]?.msg;
          this.errorPassword = data?.errors[1]?.msg;
        } else {
          this.errorMessage = data.msg;
        }
        this.btnLoading = false;
      }
    },
    openRecoverPassword() {
      this.$refs.recoverPassword.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.content-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-logo {
  width: 250px;
}
</style>
